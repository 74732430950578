import React from "react";
import { useRouter } from "next/router";
import Heading from "@insightfulscience/atomic-react/Heading";
import Paragraph from "@insightfulscience/atomic-react/Paragraph";
import Link from "@insightfulscience/atomic-react/Link";
import routes from "../routes";

const Error401 = () => {
	const { asPath } = useRouter();
	return (
		<>
			<Heading
				Tag="h1"
				sx={{ fontSize: "xl", fontColor: "3", textAlign: "center" }}
				spacing={{ mb: 6 }}
			>
				Not Authorized
			</Heading>
			<Paragraph sx={{ fontSize: "s", textAlign: "center" }}>
				We’re sorry. You are not authorized to access the requested resource.
				<br />
				<br />
				Please{" "}
				<Link
					sx={{ fontSize: "s" }}
					href={`${routes.auth.logout}?returnUrl=${encodeURIComponent(asPath)}`}
				>
					sign in
				</Link>{" "}
				with a different account, or{" "}
				<Link sx={{ fontSize: "s" }} href={routes.external.contactUs}>
					contact us
				</Link>{" "}
				for further assistance.
			</Paragraph>
		</>
	);
};

export default Error401;
