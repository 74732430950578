import React from "react";
import Heading from "@insightfulscience/atomic-react/Heading";
import Paragraph from "@insightfulscience/atomic-react/Paragraph";

const Error503 = () => (
	<>
		<Heading
			Tag="h1"
			sx={{ fontSize: "xl", fontColor: "3", textAlign: "center" }}
			spacing={{ mb: 6 }}
		>
			Service Unavailable
		</Heading>
		<Paragraph sx={{ fontSize: "s", textAlign: "center" }}>
			We’re sorry. We’re temporarily unavailable.
		</Paragraph>
	</>
);

export default Error503;
