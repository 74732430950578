import React from "react";
import resourcesApp from "@insightfulscience/contentful/Apps/Resources";
import pipe from "@insightfulscience/shared-utils/fn/pipe";
import SiteLayout from "../../SiteLayout";
import SectionTryForFree from "../../SectionTryForFree";
import WithServices from "../../services";
import ResourcesSectionAcademy from "../../ResourcesSectionAcademy";
import ResourcesSectionQuickCals from "../../ResourcesSectionQuickCals";
import ResourcesSectionKnowledgebase from "../../ResourcesSectionKnowledgebase";
import ResourcesSectionPopularArticles from "../../ResourcesSectionPopularArticles";
import withErrorHandling from "../../AppError/withErrorHandling";

const resourcesLayout = ({ children }) => {
	return (
		<SiteLayout>
			{children}
			<SectionTryForFree />
		</SiteLayout>
	);
};

const ResourcesApp = resourcesApp({
	Layout: resourcesLayout,
	contentfulProps: {
		environment: process.env.CTF_ENV,
		space: process.env.CTF_SPACE,
		accessToken: process.env.CTF_TOKEN,
		proxy: null,
		filter: {
			"fields.id[in]": "getting-started,tutorials-guides,latest-resources",
		},
	},
	pageProps: {
		brandCode: "gp",
		siteDomain: "https://www.graphpad.com",
		cdnDomain: process.env.STATIC_SITE_DOMAIN,
		customSections: [
			{
				id: "academy",
				blockOrder: 1,
				Component: ResourcesSectionAcademy,
			},
			{
				id: "quick-calcs",
				blockOrder: 2,
				Component: ResourcesSectionQuickCals,
			},
			{
				id: "knowledgebase",
				blockOrder: 3,
				Component: ResourcesSectionKnowledgebase,
			},
			{
				id: "popular-articles",
				blockOrder: 4,
				Component: ResourcesSectionPopularArticles,
				props: {
					className: "pt-0",
				},
			},
		],
	},
});

export default pipe(ResourcesApp, withErrorHandling, WithServices);
