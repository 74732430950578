import React from "react";
import { Col, Row } from "@insightfulscience/atomic-react/Grid";
import Line from "@insightfulscience/atomic-react/Line";
import KnowledgebaseSearchBar from "@src/KnowledgebaseSearchBar";

const ResourcesSectionKnowledgebase = () => (
	<>
		<Line opacity={100} spacing={{ mb: 20 }} />

		<Row utility={{ justify: "center" }}>
			<Col md={8}>
				<KnowledgebaseSearchBar hasOwnSection={false} />
			</Col>
		</Row>
	</>
);

export default ResourcesSectionKnowledgebase;
