import React from "react";
import Heading from "@insightfulscience/atomic-react/Heading";
import Paragraph from "@insightfulscience/atomic-react/Paragraph";
import Link from "@insightfulscience/atomic-react/Link";
import routes from "../routes";

const Error403 = () => (
	<>
		<Heading
			Tag="h1"
			sx={{ fontSize: "xl", fontColor: "3", textAlign: "center" }}
			spacing={{ mb: 6 }}
		>
			Not Permitted
		</Heading>
		<Paragraph sx={{ fontSize: "s", textAlign: "center" }}>
			We’re sorry, you don’t have sufficient permissions to access this resource.
			<br /> <br />
			Please{" "}
			<Link sx={{ fontSize: "s" }} href={routes.external.contactUs}>
				contact us
			</Link>{" "}
			for further assistance.
		</Paragraph>
	</>
);

export default Error403;
