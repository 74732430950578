import React from "react";
import { isPromise } from "@insightfulscience/shared-utils/fn";
import AppError from "./AppError";

const withErrorHandling = Component => {
	const WithErrorHandling = ({ error, ...props }) => {
		if (error == null) {
			return <Component {...props} />;
		}
		return <AppError error={error} />;
	};

	Object.assign(WithErrorHandling, Component);

	WithErrorHandling.propTypes = AppError.propTypes;

	WithErrorHandling.getInitialProps = (context, container) => {
		try {
			const props = Component.getInitialProps ? Component.getInitialProps(context, container) : {};
			return isPromise(props)
				? props.catch(err => {
						return AppError.getInitialProps({ ...context, err }, container);
				  })
				: props;
		} catch (err) {
			return AppError.getInitialProps({ ...context, err }, container);
		}
	};

	return WithErrorHandling;
};

export default withErrorHandling;
