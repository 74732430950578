import React from "react";
import { Col, Row } from "@insightfulscience/atomic-react/Grid";
import LandingSimpleSection from "@insightfulscience/atomic-react/LandingElement/SimpleSection";
import Link from "@insightfulscience/atomic-react/Link";
import UList from "@insightfulscience/atomic-react/UList";
import { PostElementTitleH3 } from "@insightfulscience/atomic-react/PostElement";

const ResourcesSectionPopularArticles = () => (
	<>
		<PostElementTitleH3 center className="mb-6">
			Popular Articles
		</PostElementTitleH3>
		<LandingSimpleSection.Body>
			<Row utility={{ justify: "center" }}>
				<Col md={10}>
					<Row mx my utility={{ justify: "center" }}>
						<Col sm={12} md={6}>
							<UList spacing={{ ml: 5 }}>
								<UList.Item sx={{ fontSize: "m" }} spacing={{ mb: 3 }}>
									<Link href="https://www.graphpad.com/support/faq/activating-a-prism-license/">
										How do I activate a Prism license?
									</Link>
								</UList.Item>
								<UList.Item sx={{ fontSize: "m" }} spacing={{ mb: 3 }}>
									<Link href="https://www.graphpad.com/support/faq/prism-academy/">
										How do I access Prism Academy?
									</Link>
								</UList.Item>
								<UList.Item sx={{ fontSize: "m" }} spacing={{ mb: 3 }}>
									<Link href="https://www.graphpad.com/support/faq/what-is-the-difference-between-correlation-and-linear-regression/">
										What is the difference between correlation and linear regression?
									</Link>
								</UList.Item>
								<UList.Item sx={{ fontSize: "m" }} spacing={{ mb: 3 }}>
									<Link href="https://www.graphpad.com/support/faq/the-anova-table-ss-and-df-in-two-way-anova/">
										Understanding the ANOVA table (SS, df, MS, F) in two-way ANOVA
									</Link>
								</UList.Item>
							</UList>
						</Col>
						<Col sm={12} md={6}>
							<UList spacing={{ ml: 5 }}>
								<UList.Item sx={{ fontSize: "m" }} spacing={{ mb: 3 }}>
									<Link href="https://www.graphpad.com/support/faqid/1790/">
										How to choose a statistical test?
									</Link>
								</UList.Item>
								<UList.Item sx={{ fontSize: "m" }} spacing={{ mb: 3 }}>
									<Link href="https://www.graphpad.com/support/faqid/1726/">
										How to plot a dose-response curves?
									</Link>
								</UList.Item>
								<UList.Item sx={{ fontSize: "m" }} spacing={{ mb: 3 }}>
									<Link href="https://www.graphpad.com/support/faq/graph-tip-how-to-make-an-xy-graph-with-a-time-scale-on-the-x-axis/">
										How to make an XY graph with a time-scale on the X axis?
									</Link>
								</UList.Item>
								<UList.Item sx={{ fontSize: "m" }} spacing={{ mb: 3 }}>
									<Link href="https://www.graphpad.com/support/faq/how-to-compare-two-means-when-the-groups-have-different-standard-deviations/">
										How to compare two means when the groups have different standard deviations?
									</Link>
								</UList.Item>
							</UList>
						</Col>
					</Row>
				</Col>
			</Row>
		</LandingSimpleSection.Body>
	</>
);

export default ResourcesSectionPopularArticles;
