import React from "react";
import { Col, Row } from "@insightfulscience/atomic-react/Grid";
import LandingSimpleSection from "@insightfulscience/atomic-react/LandingElement/SimpleSection";
import TileBlock from "@insightfulscience/atomic-react/TileBlock";
import TileBlockImage from "@insightfulscience/atomic-react/TileBlock/Image";
import TileBlockBody from "@insightfulscience/atomic-react/TileBlock/Body";
import { PostElementTitleH3 } from "@insightfulscience/atomic-react/PostElement";

const ResourcesSectionQuickCals = () => (
	<>
		<LandingSimpleSection.TitleV2 sx={{ textAlign: "center" }}>
			Quick Calcs
		</LandingSimpleSection.TitleV2>
		<PostElementTitleH3 center>
			Run statistical analyses quickly and directly in your browser
		</PostElementTitleH3>

		<LandingSimpleSection.Body>
			<Row utility={{ justify: "center" }} spacing={{ mt: 12 }}>
				<Col sm={12} md={10}>
					<TileBlock>
						<TileBlockImage src="/images/graphpad/resources/quickcalcs-categorical-data.svg" />
						<TileBlockBody>
							<TileBlockBody.Title href="/quickcalcs/catMenu/">
								Categorical Data
							</TileBlockBody.Title>
							<TileBlockBody.Description>
								Fisher&apos;s, Chi square, McNemar&apos;s, Sign test, CI of proportion, NNT (number
								needed to treat), kappa.
							</TileBlockBody.Description>
						</TileBlockBody>
					</TileBlock>
					<TileBlock>
						<TileBlockImage src="/images/graphpad/resources/quickcalcs-continuous-data.svg" />
						<TileBlockBody>
							<TileBlockBody.Title href="/quickcalcs/contMenu/">
								Continuous data
							</TileBlockBody.Title>
							<TileBlockBody.Description>
								Descriptive statistics, detect outlier, t test, CI of mean / difference / ratio /
								SD, multiple comparisons tests, linear regression.
							</TileBlockBody.Description>
						</TileBlockBody>
					</TileBlock>
					<TileBlock>
						<TileBlockImage src="/images/graphpad/resources/quickcalcs-statistical-distributions.svg" />
						<TileBlockBody>
							<TileBlockBody.Title href="/quickcalcs/distMenu/">
								Statistical distributions and interpreting P values
							</TileBlockBody.Title>
							<TileBlockBody.Description>
								Calculate P from t, z, r, F or chi-square, or vice-versa. View Binomial, Poisson or
								Gaussian distribution. Correct a P value for multiple comparisons and Bayes.
							</TileBlockBody.Description>
						</TileBlockBody>
					</TileBlock>
					<TileBlock>
						<TileBlockImage src="/images/graphpad/resources/quickcalcs-random-numbers.svg" />
						<TileBlockBody>
							<TileBlockBody.Title href="/quickcalcs/randMenu/">Random numbers</TileBlockBody.Title>
							<TileBlockBody.Description>
								Assign subjects to groups, simulate data.
							</TileBlockBody.Description>
						</TileBlockBody>
					</TileBlock>
					<TileBlock>
						<TileBlockImage src="/images/graphpad/resources/quickcalcs-chemical-data.svg" />
						<TileBlockBody>
							<TileBlockBody.Title href="/quickcalcs/chemMenu/">
								Chemical and radiochemical data
							</TileBlockBody.Title>
							<TileBlockBody.Description>
								Create and convert molar solutions, convert moles & grams, radioactivity
								calculations.
							</TileBlockBody.Description>
						</TileBlockBody>
					</TileBlock>
				</Col>
			</Row>
		</LandingSimpleSection.Body>
	</>
);

export default ResourcesSectionQuickCals;
