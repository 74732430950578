import React from "react";
import Heading from "@insightfulscience/atomic-react/Heading";
import Paragraph from "@insightfulscience/atomic-react/Paragraph";
import PageTitle from "@insightfulscience/smart-react/PageTitle";
import { useLocale } from "@insightfulscience/smart-react/i18";

const Error404 = () => {
	const [t] = useLocale("app-error");
	return (
		<>
			<PageTitle title={t("404.META_TITLE")} />
			<Heading
				Tag="h1"
				sx={{ fontSize: "xl", fontColor: "3", textAlign: "center" }}
				spacing={{ mb: 6 }}
			>
				{t("404.TITLE")}
			</Heading>
			<Paragraph sx={{ fontSize: "s", textAlign: "center" }}>{t("404.DESCRIPTION")}</Paragraph>
		</>
	);
};

export default Error404;
