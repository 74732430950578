import React from "react";
import LandingSimpleSection from "@insightfulscience/atomic-react/LandingElement/SimpleSection";
import { Col, Row } from "@insightfulscience/atomic-react/Grid";
import UList from "@insightfulscience/atomic-react/UList";
import Image from "@insightfulscience/atomic-react/Image";
import Button from "@insightfulscience/atomic-react/Button";

const ResourcesSectionAcademy = () => (
	<>
		<LandingSimpleSection.TitleV2 spacing={{ mb: 6 }} sx={{ textAlign: "center" }}>
			Advance Your Skills with Prism Academy
		</LandingSimpleSection.TitleV2>
		<LandingSimpleSection.Body>
			<Row utility={{ justify: "center" }}>
				<Col md={10}>
					<Row mx my utility={{ justify: "center" }}>
						<Col sm={12} md={5}>
							<UList spacing={{ ml: 5 }}>
								<UList.Item sx={{ fontSize: "m" }} spacing={{ mb: 3 }}>
									125+ videos and hours of learning material taught by highly-technical experts
								</UList.Item>
								<UList.Item sx={{ fontSize: "m" }} spacing={{ mb: 3 }}>
									Improve accuracy interpreting and presenting your research
								</UList.Item>
								<UList.Item sx={{ fontSize: "m" }} spacing={{ mb: 3 }}>
									Master the art of data visualization and presentation
								</UList.Item>
								<UList.Item sx={{ fontSize: "m" }} spacing={{ mb: 3 }}>
									Become more efficient and productive using Prism
								</UList.Item>
							</UList>
							<Button.Link
								href="/prism-academy"
								styleType="secondary"
								isOutline
								size="s"
								spacing={{ mt: 5, ml: 5 }}
							>
								Learn More
							</Button.Link>
						</Col>
						<Col md={7}>
							<Image src="/images/graphpad/resources/prism-academy.png" />
						</Col>
					</Row>
				</Col>
			</Row>
		</LandingSimpleSection.Body>
	</>
);

export default ResourcesSectionAcademy;
