import React, { Fragment, useRef } from "react";
import SectionSearchBar from "@insightfulscience/atomic-react/r2/SectionSearchBar";
import Form from "@insightfulscience/atomic-react/Form";
import Input from "@insightfulscience/atomic-react/Input";
import InputGroup from "@insightfulscience/atomic-react/InputGroup";
import Icon from "@insightfulscience/atomic-react/Icon";
import { bool } from "prop-types";

function KnowledgebaseSearchBar({ hasOwnSection = true }) {
	const searchInputRef = useRef();
	const SectionWrapper = hasOwnSection ? SectionSearchBar : Fragment;

	function handleSearchForm(event) {
		event.preventDefault();

		const searchInput = searchInputRef.current.value;
		const searchIndex = "520255";

		if (searchInput.length) {
			window.location = `/search/?index=${searchIndex}&searchquery=${searchInput}`;
		}

		return false;
	}

	return (
		<SectionWrapper>
			<SectionSearchBar.Title>Explore the Knowledgebase</SectionSearchBar.Title>
			<Form onSubmit={handleSearchForm}> {/* eslint-disable-line*/}
				<InputGroup>
					<InputGroup.Field>
						<Input forwardRef={searchInputRef} placeholder="Search..." />
					</InputGroup.Field>
					<InputGroup.Append>
						<InputGroup.Button type="submit">
							<Icon name="search" />
						</InputGroup.Button>
					</InputGroup.Append>
				</InputGroup>
			</Form>
		</SectionWrapper>
	);
}

KnowledgebaseSearchBar.propTypes = {
	hasOwnSection: bool,
};

export default KnowledgebaseSearchBar;
