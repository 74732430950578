import React from "react";
import SectionTryForFreeComponent from "@insightfulscience/atomic-react/SectionTryForFree";
import Html from "@insightfulscience/smart-react/Html";
import Button from "@insightfulscience/atomic-react/Button";
import { useLocale } from "@insightfulscience/smart-react/i18";

const SectionTryForFree = () => {
	const [t] = useLocale("section-try-for-free");
	return (
		<SectionTryForFreeComponent>
			<SectionTryForFreeComponent.Title>
				<Html Tag="span">{t("TRY_FREE_TEXT")}</Html>
			</SectionTryForFreeComponent.Title>
			<SectionTryForFreeComponent.Action>
				<Button.Link size="s" href="/demos/" className="ml-auto mr-auto">
					{t("TRY_FREE_BUTTON_TEXT")}
				</Button.Link>
			</SectionTryForFreeComponent.Action>
		</SectionTryForFreeComponent>
	);
};

export default SectionTryForFree;
